<template>
    <div class="modal add_step secondary" v-if="modelValue" :class="{active : modelValue}" @click.self="closeModal()">
        <div class="modal_container">
            <div class="modal_body">
                <button class="close_btn" @click="closeModal()"><i class="fas fa-times"></i></button>
                <div class="upload_image image_create">
                    <image-library v-model="form.dashboard_thumb" image-type="dashboard-thumb" :is-inside="true" />
                </div>
                <div class="step_content">
                    <Form @submit="handleSubmitForm" @invalid-submit="handleFormInvalidSubmit" v-slot="{ errors }" ref="new-playbook-form">
                        <div class="setting_wpr">
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Playbook Name</label>
                                    <div class="field_wpr" :class="{ 'has-error': errors.title }">
                                        <Field autocomplete="off" type="text" name="title" v-model="form.title" placeholder="ex: New Playbook" rules="required" />
                                    </div>
                                    <ErrorMessage name="title" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Playbook Categories <a class="option" @click="createCategory = !createCategory">Create Smart Category</a></label>
                                        <div class="field_wpr">
                                        <multiselect
                                            v-model="form.categories"
                                            :options="playbookCategories"
                                            :searchable="true"
                                            valueProp="id"
                                            label="name"
                                            placeholder="Select Category"
                                            mode="tags"
                                        ></multiselect>
                                    </div>
                                </div>
                            </div>
                            <div v-if="createCategory" class="form_grp mb-2">
                                <div class="group_item">
                                    <label class="input_label">Category Name</label>
                                    <Form @submit="handleCreateCategory" v-slot="{ errors }" ref="category-form">
                                        <div class="field_wpr has_suffix" :class="{ 'has-error': errors.name }">
                                            <Field autocomplete="off" type="text" name="name" v-model="categoryForm.name" placeholder="Enter Category Name" rules="required" />
                                            <span class="suffix" @click="handleCreateCategory()"><i class="fa fa-spin fa-spinner" v-if="categorySaveLoader"></i> {{ categorySaveLoader ? 'Creating' : 'Create' }}</span>
                                        </div>
                                        <ErrorMessage name="name" class="text-danger" />
                                    </Form>
                                </div>
                            </div>
                            <div class="capsule_elm">
                                <h5 class="large">Use Saved Styling?</h5>
                                <label for="saved_styling" class="switch_option capsule_btn">
                                    <input type="checkbox" id="saved_styling" v-model="form.use_default" :true-value="1" :false-value="0" hidden>
                                    <div><span></span></div>
                                </label>
                            </div>
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">
                                        Playbook Description
                                        <!-- <div class="dropdown mr-3" @click="personalizeOption = !personalizeOption" v-click-outside="hidePersonalizeOption">
                                            <i class="fas fa-user-alt"></i>
                                            <div class="drp_wrapper" v-if="personalizeOption" :class="{ active : personalizeOption }">
                                                <ul>
                                                    <li v-for="(personalize, p) of defaultPersonalize" @click="insertPersonalize(personalize)" :key="p">{{ personalize }}</li>
                                                </ul>
                                            </div>
                                        </div> -->
                                    </label>
                                    <div class="field_wpr" :class="{ 'has-error': errors.description }">
                                        <Field autocomplete="off" name="description" v-model="form.description" rules="required">
                                            <textarea name="description" id="description" v-model="form.description" cols="10" rows="10" placeholder="Description Goes Here.."></textarea>
                                        </Field>
                                    </div>
                                    <ErrorMessage name="description" class="text-danger" />
                                </div>
                            </div>
                        </div>
                    </Form>
                    <div class="action_wpr">
                        <button :disabled="loader" type="button" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                        <button :disabled="loader" type="button" class="btn save_btn" @click="handleSubmitForm"><i class="fa fa-spinner fa-spin" v-if="loader"></i> {{ loader ? 'Creating' : 'Create' }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { defineAsyncComponent } from 'vue'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    const ImageLibrary = defineAsyncComponent(() => import('@/components/image-library/ImageLibrary'))

    import constants from '@/utils/Constant'
    import Multiselect from '@vueform/multiselect'

    export default {
        name: 'New Playbook',

        data () {
            return {
                form: {
                    title: '',
                    description: '',
                    use_default: 0,
                    dashboard_thumb: '',
                    categories: [],
                },
                personalizeOption: false,
                defaultPersonalize: constants.personalize,
                createCategory: false,
                categoryForm: {
                    name: '',
                }
            }
        },

        props: {
            modelValue: Boolean,
        },

        emit: ['update:modelValue'],

        components: {
            Field,
            Form,
            ErrorMessage,
            ImageLibrary,
            Multiselect
        },

        watch: {
            modelValue (value) {
                const vm = this;

                if (value) {
                    vm.getPlaybookCategories();
                    vm.resetForm();
                }
            }
        },

        computed: mapState({
            loader: state => state.playbookModule.playbookUserLoader,
            categorySaveLoader: state => state.playbookModule.categorySaveLoader,
            playbookCategories: state => state.playbookModule.playbookCategories,
        }),

        methods: {
            ...mapActions({
                createPlaybook: 'playbookModule/createPlaybook',
                getPlaybookCategories: 'playbookModule/getPlaybookCategories',
                createPlaybookCategory: 'playbookModule/createPlaybookCategory',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            hidePersonalizeOption () {
                const vm = this;

                vm.personalizeOption = false;
            },

            insertPersonalize (personalize) {
                const vm = this;

                const txtToAdd = `{{ ${ personalize.toLowerCase().replaceAll(' ', '_') } }}`;

                vm.form.description = vm.form.description + ' ' +  txtToAdd + ' ';
            },

            handleSubmitForm () {
                const vm = this;

                const newPlaybookForm = vm.$refs['new-playbook-form'];

                newPlaybookForm.validate().then((result) => {
                    if (result.valid) {
                        vm.handleCreatePlaybook(newPlaybookForm);
                    }
                });
            },

            handleCreatePlaybook (form) {
                const vm = this;

                vm.form.setFieldError = form.setFieldError;

                vm.createPlaybook(vm.form).then((result) => {
                    if (result) {
                        if (vm.$parent.$parent && vm.$parent.$parent.refreshPlaybook) {
                            vm.$parent.$parent.refreshPlaybook();
                        } else {
                            vm.$parent.refreshPlaybook();
                        }

                        vm.closeModal();
                    }
                });
            },

            handleCreateCategory () {
                const vm = this;

                const categoryForm = vm.$refs['category-form'];

                categoryForm.validate().then((result) => {
                    if (result.valid) {
                        vm.categoryForm.setFieldError = categoryForm.setFieldError;

                        vm.createPlaybookCategory(vm.categoryForm).then((result) => {
                            if (result) {
                                vm.createCategory    = false;
                                vm.categoryForm.name = '';
                                vm.getPlaybookCategories();
                            }
                        });
                    }
                });
            },

            resetForm () {
                const vm = this;

                vm.form = {
                    title: '',
                    description: '',
                    use_default: 0,
                    dashboard_thumb: '',
                    categories: [],
                };
                vm.categoryForm = {
                    name: '',
                };
                vm.createCategory = false;
            }
        }
    }
</script>

<style scoped>
    .step_content {
        padding: 20px 30px 10px 30px;
        flex: 1;
    }

    .step_content .action_wpr {
        width: 100%;
        padding-top: 5px;
    }

    .close_btn {
        position: absolute;
        right: 15px;
        left: auto;
        top: 15px;
        border: 0;
        background: rgba(50,55,59,.6);
        font-size: 12px;
        color: #fff;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        min-width: 20px;
    }

    .switch_option h5 {
        text-align: left;
        margin: 0;
    }

    .upload_image.image_create {
        margin-bottom: 0;
    }

    .upload_image.image_create .upload_wpr {
        height: 100%;
    }
</style>